import { withZod } from '@remix-validated-form/with-zod'
import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { getFindFromFilters, type MapFiltersType } from '~/atoms'
import { type Company } from './company'
import { type Contact } from './contact'
import { type Listing } from './listing'
import { type ModelOrganization } from './organization'
import {
  LocationFilterSchema,
  MapFiltersSchema,
  type LocationFilter,
} from './shared'
import { type ModelUser, type User } from './user'
import { getMapFilterLabels } from './utils'

export type ReportPdfOptions = {
  type: string
  preparedFor?: string
  notes?: string
  showTitlePage: boolean | string
  showContents: boolean | string
  showBrokerProfile: boolean | string
  showMapPage: boolean | string
  showLeaseSpaces: boolean | string
  showBrokers: boolean | string
  showImages: boolean | string
  showParcels: boolean | string
}

export type ReportPdf = {
  _id: string
  created: string
  modified: string
  // type: string
  // showProfilePage: boolean | string
  // showMapPage: boolean | string
  status?: string
  url?: string

  options: ReportPdfOptions
}

export type Report = {
  _id: string
  tid: string
  created: string
  modified: string
  deleted?: boolean
  deletedDate?: string
  organization: ModelOrganization
  user: ModelUser
  name: string
  slug: string

  description?: string
  format?: string // app, pdf
  includeIds?: string[]
  excludeIds?: string[]
  filters?: MapFiltersType
  location?: LocationFilter
  properties?: {
    isCircle?: boolean | string
    radiusInKm?: number
    center?: number[]
  }
  sort?: { [key: string]: number } // depreciated, sort in filters
  // options: [] // include profile cover

  pdf?: ReportPdf
  options?: {
    showOriginalAttachments?: boolean | string
    showOriginalBrokers?: boolean | string
  }
  contacts?: ReportContact[]
}

export type ReportContact = Pick<
  Contact,
  '_id' | 'tid' | 'slug' | 'firstName' | 'lastName' | 'email' | 'phone'
>

export const ReportSchema = z.object({
  _id: z.string(),
  tid: z.string(),
  created: z.string(),
  modified: z.string(),
  deleted: z.boolean().optional(),
  deletedDate: z.string().optional(),
  organization: z.custom<ModelOrganization>(),
  user: z.custom<ModelUser>(),
  name: zfd.text(z.string().min(1)),
  slug: zfd.text(z.string()),
  description: zfd.text(z.string().optional()),

  includeIds: z.array(z.string()).optional(),
  excludeIds: z.array(z.string()).optional(),

  filters: MapFiltersSchema.optional(),
  location: LocationFilterSchema.optional(),

  properties: z
    .object({
      isCircle: zfd.checkbox().optional(),
      radiusInKm: zfd.numeric(z.number()).optional(),
      center: z.array(zfd.numeric(z.number())).optional(),
    })
    .optional(),

  sort: z
    .object({
      modified: zfd.numeric(z.number()).optional(),
    })
    .optional(),

  options: z.object({
    showOriginalAttachments: zfd.checkbox().optional(),
    showOriginalBrokers: zfd.checkbox().optional(),
  }),
  contacts: zfd.repeatable(
    z.array(
      z.object({
        _id: zfd.text(z.string()),
        tid: zfd.text(z.string()),
        slug: zfd.text(z.string()),
        firstName: zfd.text(z.string()),
        lastName: zfd.text(z.string()),
        email: zfd.text(z.string()),
        phone: zfd.text(z.string().optional()),
      })
    )
  ),
}) satisfies z.ZodType<Report>

export type ReportDetailContext = {
  report: Report
  listings: Listing[]
  broker: User
  company?: Company
}

export const reportPdfValidator = withZod(
  z.object({
    report: z.custom<Report>(),
    _id: z.string(),
    tid: z.string(),
    created: z.string(),
    modified: z.string(),
    status: z.string(),

    // showProfilePage: zfd.checkbox(),
    // showMapPage: zfd.checkbox(),
    options: z.object({
      type: zfd.text(z.string()),
      preparedFor: zfd.text(z.string().optional()),
      notes: zfd.text(z.string().optional()),
      showTitlePage: zfd.checkbox(),
      showContents: zfd.checkbox(),
      showBrokerProfile: zfd.checkbox(),
      showMapPage: zfd.checkbox(),
      showLeaseSpaces: zfd.checkbox(),
      showBrokers: zfd.checkbox(),
      showImages: zfd.checkbox(),
      showParcels: zfd.checkbox(),
    }),
  })
)

export function getFindFromReport(
  report: Pick<Report, 'includeIds' | 'excludeIds' | 'filters' | 'location'>
) {
  const find: { [key: string]: any } = {}
  if (report.includeIds) {
    find._id = { $in: report.includeIds }
  }
  if (report.excludeIds) {
    if (typeof find._id !== 'undefined') {
      find._id.$nin = report.excludeIds
    } else {
      find._id = { $nin: report.excludeIds }
    }
  }
  if (report.location) {
    find.location = report.location
  }
  if (report.filters) {
    return {
      ...find,
      ...getFindFromFilters(report.filters),
    }
  }
  return find
}

export function getReportFilterLabels(report: Report, includeFrom?: boolean) {
  if (report.includeIds) {
    if (!includeFrom) {
      return []
    }
    return [
      `${report.includeIds.length} selected listing${
        report.includeIds.length > 1 ? 's' : ''
      }`,
    ]
  }
  const filters: string[] = []
  if (report.location && includeFrom) {
    filters.push('Map geography')
  }
  if (report.filters) {
    filters.push(...getMapFilterLabels(report.filters))
  }
  return filters
}
